<template>
  <div class="content">
    <!-- 帮扶积分 -->
    <div class="topBox">
      <img src="./imgs/bg.png" style="width: 100%" />
      <div class="scoreBox">
        <p>帮扶积分</p>
        <p class="score">{{ score }}</p>
      </div>
    </div>
    <!-- tab切换 -->
    <van-tabs v-model="active" color="#1677FF" swipeable @click="changeTab">
      <van-tab
        v-for="(item, index) in tabList"
        :key="index"
        :title="item"
      ></van-tab>
    </van-tabs>
    <!-- 搜索 -->
    <div class="searchBox">
      <span class="searchIcon"><img src="./imgs/search.png" alt="" /></span>
      <input
        type="text"
        placeholder="输入名称进行搜索"
        @change.enter="search"
        v-model="partyName"
      />
      <span @click="search" class="searchBtn">| 搜索</span>
    </div>
    <!-- 党员列表 -->
    <div class="partyBox" v-if="partyList.length || !isLoad">
      <!-- 党员框框 -->
      <div class="partyItem" v-for="(item, index) in partyList" :key="index">
        <!-- 图片 -->
        <div class="imgBox">
          <!-- 默认图片 -->
          <img src="./imgs/avatar.png" v-if="!item.avatar" />
          <!-- 有图片 -->
          <img :src="item.avatar" v-else />
        </div>
        <!-- 信息 -->
        <div class="infoBox">
          <div>
            <div class="left">结对党员</div>
            <div class="right">{{ item.realName }}</div>
          </div>
          <div>
            <div class="left">所在支部</div>
            <div class="right">{{ item.deptName }}</div>
          </div>
          <div>
            <div class="left">出生日期</div>
            <div class="right">{{ item.birthday.split(' ')[0] }}</div>
          </div>
        </div>
        <!-- 标签 -->
        <div class="label" v-if="!active">党员:{{ item.supportName }}</div>
        <!-- 解绑按钮 -->
        <div
          class="unBindBtn"
          v-if="!active && role != 2"
          @click="unBind(item.id)"
        >
          解绑
        </div>
        <!-- 结对按钮 -->
        <div
          class="bindBtn"
          v-if="active && role != 2"
          @click="toBind(item.id)"
        >
          与TA对接
        </div>
      </div>
      <div class="noMore" v-if="!isLoad">没有更多了</div>
    </div>
    <!-- 加载 -->
    <div
      style="padding-top: 50%; text-align: center"
      v-if="!partyList.length && isLoad"
    >
      <van-loading size="24px">加载中...</van-loading>
    </div>

    <!-- 日志按钮 -->
    <div
      class="btnBox"
      v-if="!active && role != 2 && partyList.length"
      @click="goLogList"
    >
      帮扶日志
    </div>
  </div>
</template>
<script>
import { Notify } from 'vant';
import {
  requestScore,
  requestRoleInfo,
  requestPartyList,
  requestBind,
  requestUnBind,
} from '@/api/pairSupport';
export default {
  data() {
    return {
      role: '', //角色
      score: 0, //积分
      tabList: ['结对老党员', '帮扶老党员'],
      active: 0,
      partyName: '',
      partyList: [],
      isLoad: false,
    };
  },
  created() {
    this.getRoleInfo();
    this.getScore();
    this.getPartyList();
  },
  methods: {
    // 获取帮扶积分
    getScore() {
      requestScore().then((res) => {
        if (res.success) {
          this.score = res.data;
        }
      });
    },
    // 获取用户信息 1管理员or2老党员or3新党员
    getRoleInfo() {
      requestRoleInfo().then((res) => {
        if (res.success) {
          this.role = res.data;
        }
      });
    },
    // 切换
    changeTab() {
      this.isLoad = true;
      this.partyList = [];
      this.getPartyList();
    },
    // 搜索
    search() {
      this.isLoad = true;
      this.partyList = [];
      this.getPartyList();
    },
    // 动态获取老党员列表
    getPartyList() {
      requestPartyList({
        current: 1,
        size: 500,
        supportId: this.active == 1 ? 0 : '',
        realName: this.partyName,
      }).then((res) => {
        if (res.success) {
          this.isLoad = false;
          this.partyList = res.data.records;
          console.log('获取帮扶对象: ', this.partyList);
        }
      });
    },
    //结对
    toBind(id) {
      requestBind({ id }).then((res) => {
        if (res.success) {
          Notify({ type: 'success', message: '结对成功' });
          this.getPartyList();
        }
      });
    },
    // 解绑
    unBind(id) {
      requestUnBind({ id }).then((res) => {
        if (res.success) {
          Notify({ type: 'success', message: '解绑成功' });
          this.getPartyList();
        }
      });
    },
    //跳转至帮扶日志页面
    goLogList() {
      this.$router.push({ name: 'logList' });
    },
  },
};
</script>
<style scoped lang="scss">
$px: 1rem/75;
.content {
  width: 100%;
  height: 100%;
  .topBox {
    position: relative;
    .scoreBox {
      position: absolute;
      top: 48 * $px;
      left: 32 * $px;
      font-size: 32 * $px;
      color: #fff;
      .score {
        font-size: 40 * $px;
        font-weight: 500;
      }
    }
  }
  .searchBox {
    padding: 16 * $px 32 * $px;
    font-size: 28 * $px;
    position: relative;
    .searchIcon {
      position: absolute;
      left: 50 * $px;
      top: 28%;
    }
    input {
      width: 72%;
      height: 60 * $px;
      border-radius: 30 * $px;
      border: 1px solid #eeeeee;
      padding-left: 60 * $px;
      padding-right: 115 * $px;
    }
    .searchBtn {
      position: absolute;
      right: 70 * $px;
      top: 30%;
    }
  }
  .partyBox {
    box-sizing: border-box;
    padding: 32 * $px 32 * $px 100 * $px;
    height: 66%;
    overflow: scroll;
    .partyItem {
      box-sizing: border-box;
      width: 100%;
      border-radius: 8 * $px;
      border: 2 * $px solid #eee;
      padding: 24 * $px 24 * $px 100 * $px;
      display: flex;
      position: relative;
      margin-bottom: 16 * $px;
      .imgBox {
        width: 150 * $px;
        height: 150 * $px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .infoBox {
        flex: 1;
        margin-left: 24 * $px;
        font-size: 28 * $px;
        > div {
          display: flex;
          margin-bottom: 20 * $px;
        }
        .left {
          color: #999;
          margin-right: 8 * $px;
          white-space: nowrap;
        }
        .right {
          color: #333;
        }
      }
      .label {
        position: absolute;
        top: 24 * $px;
        right: 24 * $px;
        font-size: 24 * $px;
        color: #fff;
        background: #d51616;
        padding: 4 * $px 8 * $px;
      }
      .unBindBtn {
        width: 120 * $px;
        height: 64 * $px;
        background: #fff;
        border-radius: 32 * $px;
        border: 2 * $px solid #ddd;
        font-size: 28 * $px;
        color: #666;
        text-align: center;
        line-height: 64 * $px;
        position: absolute;
        bottom: 24 * $px;
        right: 24 * $px;
      }
      .bindBtn {
        width: 184 * $px;
        height: 64 * $px;
        background: #d78339;
        border-radius: 32 * $px;
        font-size: 28 * $px;
        color: #fff;
        text-align: center;
        line-height: 64 * $px;
        position: absolute;
        bottom: 24 * $px;
        right: 24 * $px;
      }
    }
    .noMore {
      text-align: center;
      font-size: 32 * $px;
      color: #999;
    }
  }

  .btnBox {
    width: 642 * $px;
    height: 96 * $px;
    background: #1677ff;
    border-radius: 48 * $px;
    position: fixed;
    bottom: 50 * $px;
    left: 54 * $px;
    font-size: 36 * $px;
    color: #fff;
    text-align: center;
    line-height: 96 * $px;
  }
}
</style>
