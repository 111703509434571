import request from '../request'
import axios from 'axios'
const token = localStorage.getItem('Blade-Auth-Token');
//请求帮扶积分
export const requestScore = (params) => {
    return request({
        url: `/blade-party-admin/pairingSupportLog/score`,
        data: params,
        method: 'GET'
    })
};
//请求该用户信息:验证登陆这是否为管理员
export const requestRoleInfo = (params) => {
    return request({
        url: `/blade-party-admin/pairing/role`,
        data: params,
        method: 'GET'
    })
};
//动态请求老党员列表
export const requestPartyList = (params) => {
    return request({
        url: `/blade-party-admin/pairing/pageNoDesensitized`,
        params: params,
        method: 'GET'
    })
};
//绑定
export const requestBind = (params) => {
    return request({
        url: `/blade-party-admin/pairing/binding`,
        params: params,
        method: 'GET'
    })
};

//解绑
export const requestUnBind = (params) => {
    return request({
        url: `/blade-party-admin/pairing/unbinding`,
        params: params,
        method: 'GET'
    })
};

//请求帮扶日志列表
export const requestLogList = (params) => {
    return request({
        url: `/blade-party-admin/pairingSupportLog/page`,
        params: params,
        method: 'GET'
    })
};
// 请求日志详情
export const requestLogDetail = (params) => {
    return request({
        url: `/blade-party-admin/pairingSupportLog/detail`,
        params: params,
        method: 'GET',
    })
};
// 请求周期配置
export const requestConfig = (params) => {
    return request({
        url: `/blade-party-admin/pairingSupportConfig/detail`,
        params: params,
        method: 'GET',
    })
};
export const requestPairList = (params) => {
    return request({
        url: `/blade-party-admin/pairing/meSupport`,
        params: params,
        method: 'GET'
    })
};
// 校验
export const requestCheck = (params) => {
    return request({
        url: `/blade-party-admin/pairingSupportLog/isCanSave`,
        data: params,
        method: 'POST',
    })
};
// 保存日志
export const requestLogSave = (params) => {
    return request({
        url: `/blade-party-admin/pairingSupportLog/save`,
        data: params,
        method: 'POST',
    })
};
// 文件上传
export const uploadFile = (params) => {
    return axios.post(`/blade-party-admin/file/put-file`, params, { headers: { 'User-Type': 'DINGTALK', 'Blade-Auth': token, 'Content-Type': 'multipart/form-data' } });
};